import AuthModal from '@components/auth/AuthModal';
import ActionButton from 'lib/src/components/button/ActionButton';
import ButtonRow from 'lib/src/components/button/ButtonRow';
import Modal from 'lib/src/components/modal/Modal';
import Description from 'lib/src/components/typography/Description';
import useIsLoggedIn from 'lib/src/hooks/useIsLoggedIn';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const buttonText = 'Save progress';

const SaveAndContinue = ({ onSave }: Props) => {
    const isLoggedIn = useIsLoggedIn();
    const history = useHistory();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showAuthModal, setShowAuthModal] = useState(false);

    const handleSave = () => {
        if (onSave) {
            onSave();
        }

        if (isLoggedIn) {
            setShowSuccessModal(true);
        } else {
            setShowAuthModal(true);
        }
    };

    return (
        <>
            <ButtonRow>
                <ActionButton source="secondary" icon="save" onClick={handleSave} type="button">
                    {buttonText}
                </ActionButton>
            </ButtonRow>

            {showSuccessModal && (
                <Modal title="Success!">
                    <Description>Your garden has been saved.</Description>
                    <ButtonRow>
                        <ActionButton source="negative" onClick={() => history.push('/')}>
                            Close
                        </ActionButton>
                    </ButtonRow>
                </Modal>
            )}

            {showAuthModal && <AuthModal closeModal={() => setShowAuthModal(false)} />}
        </>
    );
};

interface Props {
    onSave?: () => void;
}

export default SaveAndContinue;
