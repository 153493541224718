import { useContext, useEffect, useMemo, useState } from 'react';

import { QuestionnaireContext } from '@pages/questionnaire/QuestionnaireProvider';
import { useApi } from 'lib/src/utils/api';
import { WIZARD_STAGE } from 'lib/src/constants/enums';
import { APIError } from 'lib/src/types/APIError';
import Currency from 'lib/src/utils/currency';
import useForm from 'lib/src/hooks/useForm';

const useSummary = (isPaused: boolean) => {
    const {
        order,
        isFetching,
        wizardContent: { content, ...restOfWizardContent },
        products,
        setStep,
        goToPreviousStep,
        selectedOrderGuid,
        forceRefetchOrder,
    } = useContext(QuestionnaireContext);

    const api = useApi();
    const [isPosting, setIsPosting] = useState(false);
    const [error, setError] = useState<APIError | null>(null);

    const [form, handleChange] = useForm({
        howDidYouHearAboutUs: order?.howDidYouHearAboutUs ?? '',
    });

    useEffect(() => {
        if (order) {
            handleChange('howDidYouHearAboutUs', order.howDidYouHearAboutUs);
        }
    }, [order, handleChange]);

    const minimumOrder = useMemo(() => {
        const minimumOrderValue = order?.minimumOrderValue ?? 0;
        const total = order?.total ?? 0;

        return {
            isValid: total >= minimumOrderValue,
            value: Currency.fromPennies(minimumOrderValue).display({ fixedPoint: 2 }),
        };
    }, [order?.minimumOrderValue, order?.total]);

    useEffect(() => {
        forceRefetchOrder();
    }, [forceRefetchOrder]);

    const saveOrder = () => {
        api.post<{ howDidYouHearAboutUs: string }, CheckoutResponse>(
            `orders/${selectedOrderGuid}/summary`,
            form,
        )
            .catch(setError)
            .finally(() => setIsPosting(false));
    };

    const handleSubmit = () => {
        saveOrder();

        if (isPaused && !order?.canBypassPause) return;

        setIsPosting(true);

        api.post<{ howDidYouHearAboutUs: string }, CheckoutResponse>(
            `orders/${selectedOrderGuid}/checkout`,
            form,
        )
            .then(({ url }) => (window.location.href = url))
            .catch(setError)
            .finally(() => setIsPosting(false));
    };

    const paymentDisabled = !minimumOrder.isValid || (isPaused && !order?.canBypassPause);

    return {
        order,
        isFetching,
        isPosting,
        handleSubmit,
        goToPreviousStep,
        products,
        error,
        minimumOrder,
        paymentDisabled,
        setStep,
        content: { content: content[WIZARD_STAGE.SUMMARY], ...restOfWizardContent },
        form,
        handleChange,
        saveOrder,
    };
};

interface CheckoutResponse {
    url: string;
}

export default useSummary;
